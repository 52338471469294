import AOS from 'aos';
import momemt from 'moment';

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(){
      $('.nav').toggleClass('active')
  })
  $('.nav a').click(function(){
    $('.nav').removeClass('active')
  })

  // Animations
  AOS.init({
    once: true,
    offset: 200,
    duration: 1000,
  });

  // Videos section
  $('.video-item').click(function(){
    const videoId = $(this).attr('data-attribute')
    $('.featured-video').addClass('active')
    $('.featured-video iframe').attr('src', `https://www.youtube-nocookie.com/embed/${videoId}?controls=0`)
  })

  $('.close-video').click(function(){
    $('.featured-video').removeClass('active')
    $('.featured-video iframe').attr('src', ``)
  })

  // events
  $.ajax( {
		url: 'https://rest.bandsintown.com/artists/Leanna%20Crawford/events?app_id=45PRESS_LEANNA_CRAWFORD',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			if ( data.length ) {
				for ( let event of data ) {
					let location = event.venue.city + ', ' + event.venue.region;

					if ( location === ', ' ) {
						location = '';
					}

					if ( event.venue.name.toLowerCase() === 'streaming live' ) {
						location = 'Online';
					}

					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
					html += '<div class="event-time">' + momemt( event.datetime ).format( 'h:mm' ) + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn btn-brick btn-small">' + offer.type + '</a>';
					}
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	} );

});